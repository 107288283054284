import React from "react"
import MiscContent from "./layouts/MiscContent"
import HelpTransformCompanies from "./layouts/HelpTransformCompanies"
import Services from "./layouts/Services"
import Technologies from "./layouts/Technologies"
import PeopleSaying from "./layouts/PeopleSaying"
import AboutHero from "./layouts/AboutHero"
import TeamLeaders from "./layouts/TeamLeaders"
import ServiceDescription from "./layouts/ServiceDescription"
import WorkCards from "./layouts/WorkCards"
import Careers from "./layouts/Careers"

export const getPageLayout = layout => {
  const layoutName = layout?.fieldGroupName?.split("_").pop().trim()
  if (!layoutName) return null
  switch (layoutName) {
    case "MiscContent":
      return <MiscContent {...layout} />
    case "HelpTransformCompanies":
      return <HelpTransformCompanies {...layout} />
    case "Services":
      return <Services {...layout} />
    case "Technologies":
      return <Technologies {...layout} />
    case "PeopleSaying":
      return <PeopleSaying {...layout} />
    case "AboutHero":
      return <AboutHero {...layout} />
    case "TeamLeaders":
      return <TeamLeaders {...layout} />
    case "ServiceDescription":
      return <ServiceDescription {...layout} />
    case "WorkCards":
      return <WorkCards {...layout} />
    case "Careers":
      return <Careers {...layout} />
  }
}
