import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import { getPageLayout } from "../../get-layout-utils"

export const query = graphql`
  query PageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      isFrontPage
      title
      content
      uri
      slug
      parentId
      seo {
        canonical
        title
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphImage {
          altText
          sourceUrl
          title
        }
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterTitle
        twitterImage {
          altText
          sourceUrl
          title
        }
      }
      pageBuilder {
        layouts {
          ... on WpPage_Pagebuilder_Layouts_MiscContent {
            ...MiscContent
          }
          ... on WpPage_Pagebuilder_Layouts_HelpTransformCompanies {
            ...HelpTransformCompanies
          }
          ... on WpPage_Pagebuilder_Layouts_Services {
            ...Services
          }
          ... on WpPage_Pagebuilder_Layouts_Technologies {
            ...Technologies
          }
          ... on WpPage_Pagebuilder_Layouts_PeopleSaying {
            ...PeopleSaying
          }
          ... on WpPage_Pagebuilder_Layouts_AboutHero {
            ...AboutHero
          }
          ... on WpPage_Pagebuilder_Layouts_TeamLeaders {
            ...TeamLeaders
          }
          ... on WpPage_Pagebuilder_Layouts_ServiceDescription {
            ...ServiceDescription
          }
          ... on WpPage_Pagebuilder_Layouts_WorkCards {
            ...WorkCards
          }
          ... on WpPage_Pagebuilder_Layouts_Careers {
            ...Careers
          }
        }
        pageConfiguration {
          hideFooter
          hideHeaderItems
          blackHeader
        }
      }
      translation {
        englishUrl
      }
    }
  }
`
const PageTemplate = ({ data }) => {
  const { seo, pageBuilder, title, translation } = data.wpPage

  const layouts = pageBuilder.layouts || []
  return (
    <Layout
      {...pageBuilder.pageConfiguration}
      seo={seo}
      pageName={title}
      englishUrl={translation?.englishUrl}
    >
      {layouts.map(layout => getPageLayout(layout))}
    </Layout>
  )
}

export default PageTemplate
